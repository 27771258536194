import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const Styled = {
    ErrorContainer: styled(Box)(({theme}) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto',
        height: '100vh',
        fontFamily: '-apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", "Fira Sans", Avenir, "Helvetica Neue", "Lucida Grande", sans-serif', // to match with next/error styling on Web repository
        color: theme.palette.brandColors.simpleBlack,
        textAlign: 'center',
    })),
    ErrorCode: styled(Typography)(({theme}) => ({
        display: 'inline-block',
        margin: theme.spacing(0,2.5,0,0),
        padding: theme.spacing(1.25, 2.875,1.25,0),
        verticalAlign: 'top',
        height: 48,
        borderRight: `1px solid ${theme.palette.brandColors.gray30}`,
        // to match with next/error styling on Web repository:
        fontFamily: 'inherit',
        fontSize: '24px',
        fontWeight: 500,
        lineHeight: theme.spacing(3.6),
    })),
    ErrorMessage: styled(Typography)(({theme}) => ({
        display: 'inline-block',
        textAlign: 'left',
        verticalAlign: 'middle',
        // to match with next/error styling on Web repository:
        fontFamily: 'inherit',
        fontSize: '14px',
        fontWeight: 'normal',
        textTransform: 'none',
        letterSpacing: 'normal',
    })),
};

export default Styled;
