import React from 'react';

// Components
import SEO from 'src/components/SEO';
import Styled from './error-page.styled'


const ErrorStrings: { [key: string]: string } = {
    '500': 'Internal Server Error',
    '404': 'This page could not be found'
};

const ErrorPage = ({ code }: { code: string }) => {
    const message = ErrorStrings[code];

    return (
        <>
            <SEO title={`${code}: ${message}`} />
            <Styled.ErrorContainer>
                <Styled.ErrorCode variant="h5" >{code}</Styled.ErrorCode>
                <Styled.ErrorMessage variant="subtitle2" >
                    {message}
                </Styled.ErrorMessage>
            </Styled.ErrorContainer>
        </>
    );
};

export default ErrorPage;
